import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/llamar',
        name: 'LlamarTurno',
        component: () => import(/* webpackChunkName: "home" */ '../views/LlamarTurno'),
        meta: {
            requiresAuth: true,
            requiresSucursal: true,
        }
    },
    {
        path: '/asignar/:turno_id',
        name: 'AsignarTurno',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/AsignarTurno'),
        meta: {
            requiresAuth: true,
            requiresSucursal: true,
        }
    },
    {
        path: '/cajas',
        name: 'SeleccionarCaja',
        component: () => import(/* webpackChunkName: "home" */ '../views/SeleccionarCaja'),
        meta: {
            requiresAuth: true,
            requiresSucursal: true,
        }
    },
    {
        path: '/areas',
        name: 'SeleccionarArea',
        component: () => import(/* webpackChunkName: "home" */ '../views/SeleccionarArea'),
        meta: {
            requiresAuth: true,
            requiresSucursal: true,
        }
    },
    {
        path: '/areas-atender',
        name: 'AtenderArea',
        component: () => import(/* webpackChunkName: "home" */ '../views/AtenderArea'),
        meta: {
            requiresAuth: true,
            requiresSucursal: true,
        }
    },
]

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach(async (to, from, next) => {
    async function tryLogin() {
        try {
            let token = store.state.token || localStorage.getItem('token');

            if (token) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            } else {
                axios.defaults.headers.common['Authorization'] = null;
            }
            let res = await axios.get('/api/user');
            store.commit('setToken', token);
            store.commit('setUserData', res.data.data.user);
            store.commit('setIsLoggedIn', true);
            store.commit('setPermisos', res.data.data.permisos);

            return true;
        } catch (err) {
            return false;
        }
    }

    function revisarSucursal() {
        if (to.matched.some(record => record.meta.requiresSucursal) && !store.state.sucursal_id) {
            next({name: 'Home'});
        } else {
            next();
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            revisarSucursal();
        } else {
            if (await tryLogin()) {
                revisarSucursal();
            } else {
                next({
                    path: '/login',
                    params: {nextUrl: to.fullPath}
                });
            }
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (store.state.user.is_logged_in && store.state.user.data != null) {
            next({name: 'Home'})
        } else {
            if (await tryLogin()) {
                next({name: 'Home'});
            } else {
                revisarSucursal();
            }
        }
    } else {
        if (await tryLogin()) {
            if (to.matched.length === 0) {
                next({name: 'Home'});
            } else {
                revisarSucursal();
            }
        } else {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            });
        }
    }
});

export default router
