<template>
  <div id="app" class="d-flex flex-column">
    <main-menu v-if="is_logged_in"></main-menu>
    <router-view/>
    <flash-alert></flash-alert>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import MainMenu from "./components/home/MainMenu";
import {appWindow, PhysicalPosition, PhysicalSize} from "@tauri-apps/api/window";
import {currentMonitor} from '@tauri-apps/api/window';

export default {
  name: 'App',
  components: {
    MainMenu,
  },
  async mounted() {
    if (window.__TAURI__) {
      const monitor = await currentMonitor();
      console.debug(monitor)
      const taskBarSize = 80 * monitor.scaleFactor;

      let windowWidth = Number(localStorage.getItem('settings.width') ?? 400);
      if (windowWidth < 400) {
        windowWidth = 400;
      }
      await appWindow.setSize(new PhysicalSize(windowWidth, monitor.size.height - taskBarSize));
      await appWindow.setPosition(new PhysicalPosition(monitor.size.width - windowWidth, 0));

      const unlisten = await appWindow.onResized(({payload: size}) => {
        console.log('Window resized', size);
        localStorage.setItem('settings.width', size.width);
      });
    }
  },
  computed: {
    ...mapState({
      is_logged_in: state => state.user.is_logged_in,
    })
  },
  watch: {
    $route(to, from) {
      //always clear the pusher channels when we change route
      for (let channel of this.$pusher.allChannels()) {
        channel.unsubscribe();
        channel.unbind_all();
      }
    }
  },
}
</script>