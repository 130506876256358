import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {
            is_logged_in: false,
            data: null,
            permisos: null
        },
        sucursal_id: null,
        sucursal: {},
        areas_ids: [],
        areas: [],
        caja_id: null,
        caja: {},
        token: null,
    },
    mutations: {
        setUserData(state, data) {
            state.user.data = data;
        },
        setIsLoggedIn(state, data) {
            state.user.is_logged_in = data;
        },
        setPermisos(state, data) {
            state.user.permisos = data;
        },
        setSucursalId(state, data) {
            state.sucursal_id = data;
        },
        setSucursal(state, data) {
            state.sucursal = data;
        },
        setAreasIds(state, data) {
            state.areas_ids = data;
        },
        setAreas(state, data) {
            state.areas = data;
        },
        setCajaId(state, data) {
            state.caja_id = data;
        },
        setCaja(state, data) {
            state.caja = data;
        },
        setToken(state, data) {
            state.token = data;
        }
    },
    getters: {},
    actions: {},
    modules: {}
})
