<template>
  <div class="position-sticky top-0" style="z-index: 100;">
    <nav class="navbar navbar-expand-lg bg-primary navbar-dark">
      <div class="container-fluid">
        <div class="d-flex flex-row w-100 align-items-center">
          <div class="lh-1 me-1 flex-grow-1">
            <router-link class="navbar-brand fw-bold" to="/">Rita</router-link>
            <div v-if="user.is_logged_in" class="text-white mt-1 small">
              <i class="fa fa-user-alt"></i> {{user.data.nombre + ' ' + user.data.apellidos}}
            </div>
          </div>
          <div class="flex-grow-1">
            <div class="d-flex flex-row justify-content-end align-items-stretch">
              <router-link class="btn btn-sm btn-outline-light me-2"
                           :to="{name: 'SeleccionarCaja'}"
                           v-if="$permisos.can('turnos.asignacion')">
                <i class="fa fa-cash-register"></i> Cajas
              </router-link>
              <router-link class="btn btn-sm btn-outline-light me-2"
                           :to="{name: 'SeleccionarArea'}"
                           v-if="$permisos.can('turnos.atencion_area')">
                <i class="fa fa-stethoscope"></i> Áreas
              </router-link>
              <div>
                <button @click="logout" class="btn btn-sm btn-danger h-100" v-b-tooltip.leftbottom="'Cerrar sesión'">
                  <i class="fa fa-fw fa-sign-out-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'Menu',
  data() {
    return {
      project_name: process.env.VUE_APP_NAME ?? 'Tresfactorial',
    }
  },
  methods: {
    logout() {
      this.$store.commit('setUserData', null);
      this.$store.commit('setIsLoggedIn', false);
      this.$store.commit('setToken', null);
      localStorage.removeItem("token");
      this.$router.replace('/login');
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  }
}
</script>