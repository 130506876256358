import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import permisos from "./permisos";
import {BootstrapVue} from 'bootstrap-vue'

const {DateTime} = require("luxon");
const {pusher} = require('./pusher');

const theme = process.env.VUE_APP_THEME;

if (theme === 'chopo') {
    import("./styles/chopo.theme.scss");
} else if (theme === 'tolsa') {
    import("./styles/tolsa.theme.scss");
} else if (theme === 'demo') {
    import("./styles/demo.theme.scss");
}

import Modal from "./components/utils/Modal";
import Paginador from "./components/utils/Paginador";
import FormError from "./components/utils/FormError";
import FlashAlert from "./components/utils/FlashAlert";
import DatePicker from "./components/utils/DatePicker";
import TextCounter from "./components/utils/LabelTextCounter";

Vue.config.productionTip = false

window.axios = require("axios").default;
window.$ = require('jquery');
window.jQuery = require('jquery');

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;

require('@popperjs/core');
window.bootstrap = require('bootstrap');
require('bootstrap-select');
$.fn.selectpicker.Constructor.BootstrapVersion = '5';
require('bootstrap-datepicker');
require('bootstrap-datepicker/js/locales/bootstrap-datepicker.es');

axios.get('/sanctum/csrf-cookie');

Vue.use(BootstrapVue);

Vue.component('modal', Modal);
Vue.component('paginador', Paginador);
Vue.component('form-error', FormError);
Vue.component('flash-alert', FlashAlert);
Vue.component('date-picker', DatePicker);
Vue.component('text-counter', TextCounter);

Vue.prototype.$permisos = permisos;
Vue.prototype.$pusher = pusher;
Vue.prototype.$refreshSelectPicker = () => {
    Vue.nextTick(() => {
        $('.selectpicker').selectpicker('refresh');
    });
};

Vue.mixin({
    methods: {
        formatoFecha(value, format = "dd/MM/yyyy") {
            return DateTime.fromISO(value).toFormat(format);
        },
        textSubstring(string, length = 40) {
            if (string) {
                if (string.length > length) {
                    return string.substring(0, length) + '...'
                } else {
                    return string
                }
            } else {
                return string;
            }
        },
        formatoNumero(numero, formato = 'en-ES', decimals = 2) {
            let numeroFormateado = 0;
            if (numero && numero !== '' && numero !== null && numero !== undefined && !isNaN(numero)) {
                numeroFormateado = Intl.NumberFormat('en-ES', {
                    maximumFractionDigits: decimals,
                }).format(numero);
            }
            return numeroFormateado;
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
